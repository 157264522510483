import React from 'react';
import "./AllProducts.css"
import Card from '../Card/Card';
// import { useNavigate } from 'react-router-dom';


export default function AllProducts({ apiData }) {
    console.log(apiData , 'apiData all products')
    return (
        <div className='main-all-products' >
            {apiData?.length > 0 ? apiData?.map((pro, index) => {
                // console.log(pro, 'discount type all products')
                return (
                    <Card
                        img={pro?.ItemImage}
                        title={pro?.ItemName}
                        price={pro?.Price}
                        id={pro?.ItemId}
                        SaleWithOutStock={pro?.SaleWithOutStock}
                        ItemQty={pro?.ItemQty}
                        CategoryName={pro?.CategoryName}
                        Currency={pro?.Currency}
                        DiscountWithPrice = {pro?.DiscountWithPrice}
                        Discount = {pro?.Discount}
                        ApplyDiscount = {pro?.ApplyDiscount}
                        DiscountType = {pro?.DiscountType}
                        key={index}
                    />
                )
            }) :
                <div className='main-all-products'>
                    {/* <h1 className='heading-allproducts'>No Products found</h1> */}
                </div>
            }
        </div>
    );
}

