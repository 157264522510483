import React, { useEffect, useRef, useState } from 'react'
import "./OurBestSellers.css"
import { useSelector } from 'react-redux'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_METHOD } from '../../../api/api';
import OurBestSellerCard from './OurBestSellerCard/OurBestSellerCard';
import Loader from "../../Loader/Loader"
import { useMemo } from 'react';

export default function OurBestSellers() {
    const category = useSelector((state) => state.store.category);
    const memoizedCategory = useMemo(() => category || [], [category]);
    const [categoryId, setCategoryId] = useState(memoizedCategory.length > 0 ? memoizedCategory[0].Id : '');
    const [selectedCategory, setSelectedCategory] = useState(memoizedCategory.length > 0 ? memoizedCategory[0].CategoryName : '');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const sliderRef = useRef(null);

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller next-arrow-our-best-seller" onClick={onClick}>
            &#10095;
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller prev-arrow-our-best-seller" onClick={onClick}>
            &#10094;
        </div>
    );

    const sliderSettings = {
        infinite: products.length > 1,
        speed: 500,
        slidesToShow: products.length === 0 ? 1 : (products.length < 4 ? products.length : 4),
        slidesToScroll: products.length === 0 ? 1 : (products.length < 4 ? products.length : 4),
        centerMode: false,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : (products.length < 5 ? products.length : 5),
                    slidesToScroll: products.length === 0 ? 1 : (products.length < 5 ? products.length : 5),
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : (products.length < 4 ? products.length : 4),
                    slidesToScroll: products.length === 0 ? 1 : (products.length < 4 ? products.length : 4),
                },
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    slidesToScroll: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    slidesToScroll: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    slidesToScroll: products.length === 0 ? 1 : (products.length <= 2 ? products.length : 3),
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 655,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : 2,
                    slidesToScroll: products.length === 0 ? 1 : 2,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : 1,
                    slidesToScroll: products.length === 0 ? 1 : 1,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: products.length === 0 ? 1 : 1,
                    slidesToScroll: products.length === 0 ? 1 : 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
        nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
        prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    };


    const handleCategory = (id, categoryName) => {
        setCategoryId(id);
        setSelectedCategory(categoryName);
    }

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            if (categoryId) {
                const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${categoryId}`);
                setProducts(res || []);
            }
            setLoading(false);
        };
        fetchProducts();
    }, [categoryId, memoizedCategory]);

    return (
        <div className='main-our-best-sellers' >
            <div className='our-best-seller-text'>
                {loading && <Loader />}
                <h2>Our best sellers</h2>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {memoizedCategory?.slice(0, 3)?.map((cat, index) => {
                        return (
                            <button key={index} className={`our-best-seller-btn ${selectedCategory === cat?.CategoryName ? 'selected' : ''}`}
                                onClick={() => handleCategory(cat?.Id, cat?.CategoryName)} >
                                {cat?.CategoryName}
                            </button>
                        )
                    })}
                </div>
            </div>
            <br /> <br />
            <div className={`best-seller-slider ${products.length <= 2 ? 'our-best-seller-width-half' : 'our-best-seller-width-full'}`}>
                <Slider {...sliderSettings}  >
                    {products?.map((product, index) => {
                        return (
                            <OurBestSellerCard
                                title={product?.ItemName}
                                price={product?.Price}
                                img={product?.ItemImage}
                                Id={product?.ItemId}
                                SaleWithOutStock={product?.SaleWithOutStock}
                                ItemQty={product?.ItemQty}
                                DiscountWithPrice={product?.DiscountWithPrice}
                                Discount={product?.Discount}
                                ApplyDiscount={product?.ApplyDiscount}
                                DiscountType={product?.DiscountType}
                                key={index}
                            />
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
