import React, { useState } from 'react';
import './Footer.css';
import Button from '../Button/Button';
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import visa from "../../assets/visa.jpeg"
import paypal from "../../assets/paypal.jpeg"
import american from "../../assets/american.jpeg"
import c2 from "../../assets/2c-checkout.jpeg"
import discover from "../../assets/discover.jpeg"
import master from "../../assets/master.jpeg"
import jcb from "../../assets/jcb.jpeg"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { POST_METHOD } from '../../api/api'
import SearchOrder from '../CartComponents/SearchOrder/SearchOrder';
import PopupForm from '../CartComponents/PopupForm/PopupForm';
import Loader from "../Loader/Loader"

export default function Footer() {
    const category = useSelector((state) => state.store.category);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [popupSucess, setPopupSucess] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [sucess, setSucess] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const res = await POST_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/SignupEmailUsForLeatherCraft?Email=${email}`);
        console.log(res, 'newsletter res');
        if (res.success) {
            setPopupSucess(true);
            setFormMessage(res.data);
            setSucess(true);
            setLoading(false);
            setTimeout(() => {
                setPopupSucess(false)
            }, 3000)

            setEmail('');
        }
        else if (email === '') {
            setPopupSucess(true);
            setSucess(false);
            setFormMessage('Email is required');
            setLoading(false);
            setTimeout(() => {
                setPopupSucess(false)
            }, 5000)
        }
    }
    return (
        <footer>
            {loading && <Loader />}
            <div className="footer-container">
                <div className="footer-column">
                    <h4 onClick={() => navigate('/shop')}>Shop</h4>
                    <ul>
                        {category?.map((cat, index) => {
                            return (
                                <li onClick={() => navigate(`/category/${cat?.Id}`)} key={index}><p>{cat?.CategoryName}</p></li>
                            )
                        })}
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Customer Service</h4>
                    <ul>
                        <li onClick={() => navigate('/about')}><p> About US</p></li>
                        <li onClick={() => navigate('/contact')}> <p> Contact US</p></li>
                        <li onClick={() => navigate('/privacypolicy')}><p >Privacy Policy</p></li>
                        <li onClick={() => navigate('/deliverypolicy')} ><p >Delivery Policy</p></li>
                        <li onClick={() => navigate('/refundpolicy')}><p>Refund Policy </p></li>
                        <li onClick={() => navigate('/termsconditions')}> <p>Terms & Conditions </p></li>
                        <li>
                            <h4>Order Tracking</h4>
                            <SearchOrder />
                        </li>
                    </ul>
                </div>
                <div className="footer-column-bigger">
                    <h4>Newsletter Signup </h4>
                    <ul style={{ width: '100%' }}>
                        <li>
                            <label className='newsletter-label'>Recieve our latest update about our products and promotions</label> <br />
                            <div className='newsletter-subscribe-div'>
                                <input placeholder='Enter your email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <Button text='Submit' style={{ lineHeight: 0, padding: '0rem', height: '38px' }} className='submit-btn' onClick={handleSubmit} />
                            </div>
                        </li>

                        <li>
                            <h4>Address</h4>
                            <p style={{ fontSize: '0.9rem' }} >Pakistan, Sindh, Karachi, L1274, sector 4B, Surjani Town, Karachi Central
                                POBOX number 07510</p>
                        </li>

                        <li>
                            <h4>Follow us on</h4>
                            <div className='social-media-icons'>
                                <a href='https://web.facebook.com/profile.php?id=61564775320195' rel='noreferrer' target='_blank'> <FaFacebook /> </a>
                                <a href='https://www.instagram.com/leatherscrafts/' rel='noreferrer' target='_blank'> <FaInstagram /> </a>
                                <a href='https://wa.me/17139255623' rel='noreferrer' target='_blank'> <FaWhatsapp /> </a>
                                <a href='https://twitter.com' rel='noreferrer' target='_blank'> <FaXTwitter /> </a>
                                <a href='mailto:store@leatherscrafts.com'> <BiLogoGmail /> </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Payment Method</h4>
                    <ul>
                        <div className='payment-methods-div'>
                            <img src={visa} alt='visa' />
                            <img src={paypal} alt='paypal' />
                            <img src={american} alt='american-express' />
                            <img src={c2} alt='2c-checkout' />
                            <img src={discover} alt='discover' />
                            <img src={master} alt='master' />
                            <img src={jcb} alt='jcb' />
                        </div>
                    </ul>
                </div>
            </div>
            {popupSucess &&
                <PopupForm
                    showPopup={popupSucess}
                    message={formMessage}
                    onClose={() => setPopupSucess(false)}
                    title='Subscription form'
                    sucess={sucess}
                />
            }
        </footer>
    );
}

