import React, { useEffect, useState, useRef } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_METHOD } from '../../../api/api';
import OurBestSellerCard from '../../HomePageComponents/OurBestSellers/OurBestSellerCard/OurBestSellerCard';
// import { useDispatch, useSelector } from 'react-redux';
// import { setWindowWidth } from '../../../stores/slice';

export default function RelatedProducts({ categoryId }) {
    const [products, setProducts] = useState([]);
  
    const sliderRef = useRef(null);

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller next-arrow-our-best-seller" onClick={onClick}>
            &#10095;
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow-our-best-seller prev-arrow-our-best-seller" onClick={onClick}>
            &#10094;
        </div>
    );

    const sliderSettings = {
        infinite: products.length > 1,
        speed: 500,
        slidesToShow: products.length < 4 ? products.length : 4,
        slidesToScroll: products.length < 4 ? products.length : 4,
        centerMode: false,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: products.length < 5 ? products.length : 5,
                    slidesToScroll: products.length < 5 ? products.length : 5,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: products.length < 4 ? products.length : 4,
                    slidesToScroll: products.length < 4 ? products.length : 4,
                }
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: products.length <= 2 ? products.length : 3,
                    slidesToScroll: products.length <= 2 ? products.length : 3,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: products.length <= 2 ? products.length : 3,
                    slidesToScroll: products.length <= 2 ? products.length : 3,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: products.length <= 2 ? products.length : 3,
                    slidesToScroll: products.length <= 2 ? products.length : 3,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 655,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
        nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
        prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetItemCategoryWise?CampusId=50&CategoryId=${categoryId}`);
            setProducts(res || []);
        }
        fetchProducts();
    }, [categoryId]);

    return (
        <div className='main-our-best-sellers'  >
            <div className={`best-seller-slider ${products.length <= 2 ? 'our-best-seller-width-half' : 'our-best-seller-width-full'}`}>
                <Slider {...sliderSettings} >
                    {products?.map((product, index) => {
                        return (
                            <OurBestSellerCard
                                title={product?.ItemName}
                                price={product?.Price}
                                img={product?.ItemImage}
                                Id={product?.ItemId}
                                SaleWithOutStock={product?.SaleWithOutStock}
                                ItemQty={product?.ItemQty}
                                DiscountWithPrice={product?.DiscountWithPrice}
                                Discount={product?.Discount}
                                ApplyDiscount={product?.ApplyDiscount}
                                DiscountType={product?.DiscountType}
                                key={index}
                            />
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}
